import React, { useRef, useState, useEffect } from "react";
import MenuBar from "./MenuBar";
import styles from "./css/Base.module.css";
import "./css/About.css";
import aboutData from "./api/about.json";
import Footer from "./Footer";
import { importAll } from "./methods.js";
import { useLocation } from "react-router-dom";
import fbLogo from "./img/fb.png";

function AboutIcon({ imgUrl, desc }) {
  return (
    <div className={`${styles.text_center} about_col`}>
      {imgUrl && <img src={imgUrl} alt="" />}
      <p className={`${styles.font_huninn}`}>{desc}</p>
    </div>
  );
}

function About() {
  const [scrollToContact, setScrollToContact] = useState(false);
  const location = useLocation();
  const desc = aboutData.aboutDesc;

  let passBetweenRoute = null;
  location.state
    ? (passBetweenRoute = location.state.isScroll.scrollToSection)
    : (passBetweenRoute = false);
  const positionRefs = useRef(null);
  const aboutIconUrl = importAll(
    require.context("./img/about", false, /\.(png|jpe?g)$/)
  );

  const aboutIconCol = Object.values(aboutIconUrl).map((eachUrl, index) => (
    <AboutIcon imgUrl={eachUrl} desc={desc[index]} key={index} />
  ));

  function handleScrollToContact(name) {
    const positionNode = positionRefs.current;
    const sectionNode = positionNode.querySelector(`#${name}`);
    sectionNode.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  useEffect(() => {
    if (passBetweenRoute !== false) {
      handleScrollToContact("contact_sec");
    } else {
      handleScrollToContact("about_sec");
    }
  });

  return (
    <div ref={positionRefs}>
      <MenuBar origin="about" onClick={handleScrollToContact} />
      <div id="about_sec" className="about_sec">
        <h1 className={`${styles.font_huninn}`}>We</h1>
        <div
          className={`
            ${styles.d_flex}
            ${styles.align_items_end}
        `}
        >
          <div>
            <p className="text_wh">
              身為一個服務客戶的廣告公司，里安不斷提醒自己，從經營理念開始直到第一線的作業模式，透過實際的操作、激盪及調整，把客戶的事當作是自己的事，為達到目標繼續努力。
            </p>
            <p className="text_wh">
              L.i.O.n
              <br />
              里安國際，有的是衝勁，沒有的是霸氣；
              有彈性，沒有身段；富創新，而厭守舊。
            </p>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/profile.php?id=61563830270977"
          >
            <img src={fbLogo} alt="里安國際臉書粉絲專頁" />
          </a>
        </div>
      </div>
      <div className="about_sec">
        <h1 className={`${styles.font_huninn}`}>Service</h1>
        <div
          className={`
          ${styles.d_flex}
          ${styles.justify_content_center}
          ${styles.justify_content_left_md}
          ${styles.flex_wrap}
          ${styles.align_items_center}
      `}
        >
          {aboutIconCol}
        </div>
      </div>
      <div className="about_sec">
        <h1 className={`${styles.font_huninn}`}>Clients</h1>
        <p className={`${styles.font_huninn}`}>
          渣打銀行 /中國信託 / 台新銀行 / 遠東商銀 / 凱基銀行 / 永豐銀行 /
          <br></br>
          星展銀行 / 台北富邦 / 臺灣土地銀行 / 臺灣銀行 / 華南銀行 / 元大銀行 /
          <br></br>
          合作金庫 / 新加坡大華銀行
        </p>
        <p className={`${styles.font_huninn}`}>
          富達投信 / 施羅德投信 / 兆豐投信 / 聯博投信 / 野村投信 / 第一金投信 /
          <br></br>
          法銀巴黎投顧 / 美盛投顧 / 大華銀投顧 / 百達投顧 / 永豐投顧 / 元大證券
          /<br></br>
          保德信證券 / 臺銀證券 / 三商美邦人壽 / 富邦人壽 / 臺銀人壽 / 合庫人壽
          / 鉅亨網
        </p>
        <p className={`${styles.font_huninn}`}>
          全家便利店 /萊爾富便利商店 / 味王公司 / 味丹企業 / 英僑商會 /{" "}
          <br></br>
          英商邦史都華 / 特力屋 / 冠革貿易 / 國立臺灣大學 / 國立政治大學 /{" "}
          <br></br>
          臺北醫學大學 / 臺灣休閒農業學會
        </p>
      </div>
      <div id="contact_sec" className={`about_sec ${styles.position_relative}`}>
        <h1 className={`${styles.font_huninn}`}>Contact</h1>
        <p className="text_wh">
          如果您需要任何專業上的諮詢，或與我們分享您對品牌的期待，請透過以下方式留下訊息或直接與我們聯繫，
          <br />
          我們將會盡快回覆您的訊息並竭誠地為您服務。
        </p>
        <p className="text_wh">
          tel <span className="divide_icon">|</span>
          <a
            className={`${styles.font_weight_bold} text_wh`}
            href="tel:02-2581-8501"
          >
            02-2581-8501
          </a>
        </p>
        <p className="text_wh">
          fax <span className="divide_icon">|</span>
          <a
            className={`${styles.font_weight_bold} text_wh`}
            href="fax:02-2542-8111"
          >
            02-2542-8111
          </a>
        </p>
        <p className="text_wh">
          email <span className="divide_icon">|</span>
          <a
            className={`${styles.font_weight_bold} text_wh`}
            href="mailto:lion@richad.com.tw"
          >
            lion@richad.com.tw
          </a>
        </p>
        <p className="text_wh">
          add <span className="divide_icon">|</span>
          104016 台北市中山區中山北路二段62巷4號1樓
        </p>
        <Footer origin={""} />
      </div>
    </div>
  );
}

export default About;
