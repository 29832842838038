import React, { useRef, useState } from "react";
import logo from "./img/logo.svg";
import aboutLogo from "./img/aboutLogo.svg";
import textLogo from "./img/textLogo.svg";
import workLogo from "./img/workLogo.svg";
import singleLogo from "./img/singleLogo.svg";
import "./css/MenuBar.css";
import styles from "./css/Base.module.css";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { checkMobile } from "./methods";

import GenreFilterButton from "./GenreFilterButton";

function MenuItem({ destination, label }) {
  return (
    <li
      className={`
      Menubar_list_item 
      ${styles.position_relative}
  `}
    >
      <Link to={destination}>{label}</Link>
    </li>
  );
}

function MenuBarLogo({ aboutLogo, textLogo }) {
  return (
    <div
      className={`Menubar_logo_wrap 
        ${styles.d_flex}
        ${styles.justify_content_center} 
        ${styles.align_items_center}
        `}
    >
      <Link to="/">
        <img
          className="Menubar_logo Menubar_logo_about"
          src={aboutLogo}
          alt="richad-logo"
        />
      </Link>
      {textLogo && (
        <img className="Menubar_list_logo" src={textLogo} alt="里安國際" />
      )}
    </div>
  );
}

function MenuBarList({ isMobile, onClick, isScroll }) {
  return (
    <div
      className={`Menubar_list_wrap_about +
        ${
          !isMobile
            ? `Menubar_list_wrap_about 
            ${styles.flex_column} 
            ${styles.d_flex}
            ${styles.position_relative}
            align_items_end`
            : ""
        }
      `}
    >
      {!isMobile && (
        <img className="Menubar_list_logo" src={textLogo} alt="里安國際" />
      )}
      <ul
        className={`
          ${styles.d_flex} 
          ${styles.position_relative} 
          ${styles.list_style_none} 
          Menubar_list  
          ${
            !isMobile
              ? `${styles.text_right} ${styles.flex_wrap}`
              : `${styles.justify_content_center} ${styles.align_items_center}`
          }
        `}
      >
        <MenuItem destination={"/"} label={"Home"} />
        <MenuItem destination={"/works"} label={"Works"} />
        <MenuItem destination={"/about"} label={"About"} />

        {origin === "about" ? (
          <li className={`Menubar_list_item ${styles.position_relative}`}>
            <button onClick={onClick}>Contact</button>
          </li>
        ) : (
          <li className={`Menubar_list_item ${styles.position_relative}`}>
            <Link to="/about" state={{ isScroll: isScroll }}>
              Contact
            </Link>
          </li>
        )}

        <MenuItem destination={"/sustainability"} label={"Sustainability"} />
      </ul>
    </div>
  );
}

function MenuBarIndexPage({ isScroll }) {
  return (
    <div className="Menubar_wrap Menubar_wrap_index">
      <div
        className={`Menubar_logo_wrap 
          ${styles.d_flex}
          ${styles.justify_content_center}
          ${styles.align_items_center}
          `}
      >
        <img
          className="Menubar_logo Menubar_logo_index"
          src={logo}
          alt="richad-logo"
        />
      </div>
      <div>
        <ul
          className={`
            Menubar_list 
            ${styles.list_style_none}
            ${styles.text_right}`}
        >
          <MenuItem destination={"/works"} label={"Works"} />
          <MenuItem destination={"/about"} label={"About"} />
          <li
            className={`
              Menubar_list_item 
              ${styles.position_relative}`}
          >
            <Link to="/about" state={{ isScroll: isScroll }}>
              Contact
            </Link>
          </li>
          <MenuItem destination={"/sustainability"} label={"Sustainability"} />
        </ul>
      </div>
      <Footer origin={origin} />
    </div>
  );
}

function MenuBarTop({ onClick, origin, isScroll }) {
  const isMobile = checkMobile();
  return (
    <div
      className={
        isMobile
          ? `Menubar_wrap Menubar_wrap_about_mobile ${styles.align_items_center}`
          : `Menubar_wrap Menubar_wrap_about ${styles.d_flex}`
      }
    >
      <MenuBarLogo
        aboutLogo={aboutLogo}
        textLogo={isMobile ? textLogo : null}
      />
      {origin === "about" ? (
        <MenuBarList
          isMobile={isMobile}
          onClick={onClick}
          isScroll={isScroll}
        />
      ) : (
        <MenuBarList
          isMobile={isMobile}
          onClick={onClick}
          isScroll={isScroll}
        />
      )}

      <Footer origin={origin} />
    </div>
  );
}

function MenuBarSide({
  origin,
  handlerGenre,
  filterMethod,
  isScroll,
  genre,
  projectName,
  category,
}) {
  const isWorkPage = origin === "works";
  return (
    <div
      className={`Menubar_wrap 
        Menubar_side_wrap
        ${isWorkPage ? "Menubar_wrap_work" : "Menubar_wrap_single"} 
        ${styles.d_flex}
        ${styles.flex_column}
     `}
    >
      <div className="Menubar_logo_wrap">
        <Link to="/">
          {isWorkPage ? (
            <img className="Menubar_logo" src={workLogo} alt="richad-logo" />
          ) : (
            <img className="Menubar_logo" src={singleLogo} alt="richad-logo" />
          )}
        </Link>
        <h1
          className={`
            ${styles.font_huninn} 
            ${styles.text_right} text_wh`}
        >
          Works
        </h1>
      </div>
      {handlerGenre && (
        <div
          className={`
            ${styles.position_relative} 
            ${styles.d_flex} 
            Menubar_filter_wrap`}
        >
          <GenreFilterButton
            handlerGenre={handlerGenre}
            currentGenre={genre}
            genre={"all"}
            filterMethod={filterMethod}
            label="All"
          />

          <GenreFilterButton
            handlerGenre={handlerGenre}
            currentGenre={genre}
            genre={"package"}
            filterMethod={filterMethod}
            label="Packaging"
          />
          <GenreFilterButton
            handlerGenre={handlerGenre}
            currentGenre={genre}
            genre={"website"}
            filterMethod={filterMethod}
            label="Website"
          />
          <GenreFilterButton
            handlerGenre={handlerGenre}
            currentGenre={genre}
            genre={"graphic"}
            filterMethod={filterMethod}
            label="Graphics"
          />
        </div>
      )}
      {!isWorkPage && (
        <div className={`single_info ${styles.text_right}`}>
          <h2
            className={`${styles.text_right} ${styles.font_huninn} single_subtitle`}
          >
            {projectName}
          </h2>
          <div className="single_category_wrap">
            <p className={`${styles.text_right} single_category_text`}>
              {category === "website" ? "網頁設計" : "設計規劃及策略行銷"}
            </p>
            <p className={`${styles.text_right} single_category_pill`}>
              {category}
            </p>
          </div>
        </div>
      )}
      <div
        className={`
          ${isWorkPage ? "Menubar_list_wrap_work" : "Menubar_list_wrap_single"} 
          ${styles.d_flex} 
          ${styles.flex_column} `}
      >
        <ul
          className={`Menubar_list 
              ${styles.d_flex} 
              ${styles.position_relative} 
              ${styles.list_style_none} 
              ${styles.text_right} 
              ${styles.justify_content_center} 
              ${styles.align_items_end} 
              ${styles.flex_column}
              `}
        >
          <MenuItem destination={"/"} label={"Home"} />
          <MenuItem destination={"/works"} label={"Works"} />
          <MenuItem destination={"/about"} label={"About"} />
          <li className={`Menubar_list_item ${styles.position_relative}`}>
            <Link to="/about" state={{ isScroll: isScroll }}>
              Contact
            </Link>
          </li>
          <MenuItem destination={"/sustainability"} label={"Sustainability"} />
        </ul>
      </div>
      <Footer origin={origin} />
    </div>
  );
}

function MenuBar({ origin, projectName, category, filterMethod, onClick }) {
  // excuteScroll
  const [isScroll, setScroll] = useState({ scrollToSection: true });
  const [genre, setGenre] = useState("all");

  const handlerGenre = (genre) => {
    setGenre(genre);
  };

  if (origin === "index") {
    return <MenuBarIndexPage isScroll={isScroll} />;
  } else if (origin === "about") {
    return <MenuBarTop isScroll={isScroll} onClick={onClick} origin={origin} />;
  } else if (origin === "works") {
    return (
      <MenuBarSide
        handlerGenre={handlerGenre}
        origin={origin}
        filterMethod={filterMethod}
        isScroll={isScroll}
        genre={genre}
      />
    );
  } else if (origin === "single") {
    return (
      <MenuBarSide
        origin={origin}
        filterMethod={filterMethod}
        isScroll={isScroll}
        genre={genre}
        projectName={projectName}
        category={category}
      />
    );
  } else {
    return <MenuBarTop isScroll={isScroll} onClick={onClick} origin={origin} />;
  }
}

export default MenuBar;
