import React from "react";
import "./css/Base.module.css";
import "./css/Works.css";

export default function WorkRow({ data }) {
  return (
    <div className="grid-row">
      {data.map((eachUrl, index) => {
        let gridClass = `${"grid-"((index + 1) % 12)}`;
        return (
          <div className={gridClass}>
            <img src={eachUrl} />
          </div>
        );
      })}
    </div>
  );
}
