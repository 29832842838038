import React, { useState, useEffect } from "react";
import "./css/InteractiveIndex.css";
import styles from "./css/Base.module.css";
import MenuBar from "./MenuBar";
import SwiperSliderWrap from "./SwiperSliderWrap";
import { sortOutArray } from "./methods";

// custom hook for window.resize
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({ width: null, height: null });
  useEffect(() => {
    // handler to call on window resize
    function handlerResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener("resize", handlerResize);
    // Call handle right away so state gets updated with initial window size
    handlerResize();
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handlerResize);
    };
  }, []);
  return windowSize;
}

function InteractiveIndex({ data }) {
  // setInterval for the effect of background color changing
  function generateRandomColor() {
    const colorAlphabet = ["a", "b", "c", "d", "e", "f"];
    const colorNum = Array.from(Array(10).keys());
    const colorTextArr = colorAlphabet.concat(colorNum);
    let randColor = "";

    for (let i = 0; i < 6; i++) {
      let index = Math.floor(Math.random() * colorTextArr.length + 1);
      randColor = randColor + colorTextArr[index];
    }
    return "#" + randColor;
  }
  const [bgColor, setBgcolor] = useState(" #fa5869");
  useEffect(() => {
    const intervalId = setInterval(() => {
      setBgcolor(generateRandomColor());
    }, 7000);
    return () => clearInterval(intervalId);
  }, []);

  // setup windowSize
  const size = useWindowSize();

  // caculated out show how many group of slider on page
  let numberInRows = null;
  if (window.innerWidth <= 576) {
    numberInRows = 5;
  } else if (window.innerWidth <= 768) {
    numberInRows = 6;
  } else if (window.innerWidth <= 1280) {
    numberInRows = 7;
  } else if (window.innerWidth <= 1680) {
    numberInRows = 8;
  } else {
    numberInRows = 10;
  }

  let sortedData = sortOutArray(data, numberInRows);
  // console.log(sortedData);

  const AllSlider = sortedData.map((eachData, i) => {
    // console.log(`eachData:${i}`);
    // console.log(eachData);
    let randomizeDelayTime = 2200 + Math.floor(Math.random() * 80) * 100;
    return <SwiperSliderWrap delayTime={randomizeDelayTime} data={eachData} />;
  });

  return (
    <div
      className={`
        index_portfolio_wrap 
        ${styles.overflow_hidden}
        `}
      style={{ backgroundColor: bgColor }}
    >
      <MenuBar origin="index" />
      <div className="index_portfolio_grid">
        {AllSlider}
        {/* <SwiperSliderWrap data={data}/> */}
        {/* <SliderWrap data={data} sliderSettings={sliderSettings} /> */}
      </div>
      {/* <PortfolioGrid /> */}
      <p className="current_window_size">
        {size.width} / {size.height}
      </p>
    </div>
  );
}

export default InteractiveIndex;
