import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { scrollToTop } from "./methods";
import MenuBar from "./MenuBar";
import toTopArrow from "./img/toTopArrow.svg";
import SingleImageGallery from "./SingleImageGallery";
import "./css/Single.css";
import styles from "./css/Base.module.css";

export default function Single() {
  const location = useLocation();
  const { name, gallery, completionDate } = location.state;
  const { category } = useParams();

  return (
    <div className="single_wrap">
      <MenuBar origin={"single"} projectName={name} category={category} />
      <div
        className={`
          ${styles.position_relative}
          single_content_wrap`}
      >
        <p
          className={`
           ${styles.position_absolute}
           ${styles.text_right}
           ${styles.font_huninn}
           `}
        >
          {completionDate.year}
          <br />
          {completionDate.month}
        </p>
        <SingleImageGallery gallery={gallery} />
        <div
          onClick={() => {
            scrollToTop();
          }}
          className={`
            ${styles.position_fixed}
            ${styles.justify_content_center}
            ${styles.align_items_center}
            ${styles.d_flex} scroll_top`}
        >
          <img src={toTopArrow} alt={"to top arrow"} />
        </div>
      </div>
    </div>
  );
}
