import React, { useState } from "react";
import styles from "./css/Base.module.css";
import "./css/Works.css";
import MenuBar from "./MenuBar";
import { importAll, sortOutArray } from "./methods";
import { imgData } from "./api/imgList";
import { Outlet, Link } from "react-router-dom";

export default function Works() {
  const workImgData = importAll(
    require.context("./img/work", false, /\.(png|jpe?g|gif)$/)
  );
  const orginalData = imgData.map((eachData) => eachData);
  const [workData, setWorkData] = useState(orginalData);

  function filterImgData(categoryName) {
    let fileredData;
    if (categoryName) {
      fileredData = orginalData.filter(
        (eachData) => eachData.category === categoryName
      );
      setWorkData(fileredData);
    } else {
      setWorkData(orginalData);
    }
  }

  const generateWorkArray = sortOutArray(workData, 12);

  let workRowArray = generateWorkArray;

  const workRow = workRowArray.map((workRowArray) => (
    <div className="grid-row">
      {workRowArray?.map((eachData, index) => {
        let workGrid = (
          <Link
            className="work-item"
            to={eachData.category + "/" + eachData.urlName}
            state={{
              name: eachData.name,
              gallery: eachData.gallery,
              completionDate: eachData.completionDate,
            }}
            key={eachData.id}
          >
            <img
              key={eachData.id}
              src={eachData.workCover}
              alt={eachData.name}
            />
            <div className="work-item-overlap">
              <p className="text_center">{eachData.name}</p>
            </div>
          </Link>
        );

        let gridClass = "";
        if ((index + 1) % 12 === 0) {
          gridClass = "grid-12";
        } else {
          gridClass = `grid-${(index + 1) % 12}`;
        }

        return (
          <div className={gridClass} key={eachData.id}>
            {workGrid}
          </div>
        );
      })}
    </div>
  ));

  return (
    <div className="Works_wrap">
      <MenuBar origin={"works"} filterMethod={filterImgData} />
      <div className={`Works_album_wrap ${styles.position_relative}`}>
        <div className="grid-outer">{workRow}</div>
      </div>
      <Outlet />
    </div>
  );
}
