import React, { useRef, useState } from "react";
import "./css/SliderWrap.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/swiper-bundle.css";
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

// import required modules
// not import pagination + navigation + A11y
import { Navigation, Autoplay } from "swiper/modules";

SwiperCore.use([Autoplay]);

export default function SwiperSliderWrap({ data, delayTime }) {
  // let imgList = data.map((eachData) => eachData.portfolioCover);
  // let categoryList = data.map((eachData) => eachData.mockUp);
  const singleSlider = data.map((eachData, index) => {
    let liveDemo = eachData.liveDemo ? eachData.liveDemo : "";
    return (
      <SwiperSlide key={index}>
        {/* <div className="slide_column" key={index}> */}
        <Link
          state={{
            name: eachData.name,
            gallery: eachData.gallery,
            // liveDemo: eachData.liveDemo,
            completionDate: eachData.completionDate,
          }}
          to={"works/" + eachData.category + "/" + eachData.urlName}
        >
          <img
            className="slider_img"
            src={eachData.portfolioCover}
            alt={eachData.name}
          />
          {/* <span>{eachData.id}</span> */}
        </Link>
        {/* </div> */}
      </SwiperSlide>
    );
  });

  return (
    <div className="slider_row">
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={16}
        slidesPerView={data.length - 2}
        // centeredSlides={true}
        speed={delayTime}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
          stopOnLastSlide: false,
          waitForTransition: true,
        }}
        // sliderPerGroup={3}
        // loopFillGroupWithBlank= {true}
        loop={true}
        // navigation
        // pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        // onSwiper={(swiper) => console.log(swiper)}

        // onSlideChange={() => console.log('slide change')}
      >
        {singleSlider}
      </Swiper>
    </div>
  );
}
