import React from "react";
import styles from "./css/Base.module.css";
import "./css/Sustainability.css";
import sustainabilityDesc from "./api/sustainability_info.json";

function SustainabilityIcon({ mainIcon, name, engName }) {
  return (
    <div
      className={`sustainability_icon_wrap ${styles.position_relative} ${styles.text_center}`}
    >
      <img src={mainIcon} alt={name} />
      <p>
        {name}
        <br />
        {engName}
      </p>
    </div>
  );
}

function SustainabilityList({ list }) {
  return (
    <ul className={`${styles.list_style_none} ${styles.font_huninn}`}>
      {list?.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
}

export default function SustainabilityCol({ mainIcon, subIcon, dataIndex }) {
  const list = sustainabilityDesc.sustainbilityList[dataIndex];
  const { name, eng_name } = sustainabilityDesc.sustainabilityDesc[dataIndex];

  return (
    <div
      className={`sustainability_col ${styles.position_relative} ${styles.d_flex}`}
    >
      <SustainabilityIcon mainIcon={mainIcon} name={name} engName={eng_name} />
      <div className={`sustainability_sub_wrap ${styles.full_width}`}>
        <SustainabilityList list={list} />
        {subIcon &&
          subIcon.map((eachValue) => (
            <img
              className={`sustainability_sub_icon`}
              src={eachValue}
              alt={name}
            />
          ))}
      </div>
    </div>
  );
}
