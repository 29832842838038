import React from "react";
import "./css/Footer.css";
import styles from "./css/Base.module.css";

function getFullYear() {
  const dt = new Date();
  const currentYear = dt.getFullYear();
  return currentYear;
}

function Footer({ origin }) {
  return (
    <p
      className={`
        ${styles.text_center} 
        ${styles.position_absolute}
        Footer_text 
        ${origin === "index" ? "Footer_text_index" : "Footer_text_about"}`}
    >
      {getFullYear()} ©Lion International
    </p>
  );
}

export default Footer;
