import React from "react";

export default function GenreFilterButton({
  handlerGenre,
  filterMethod,
  genre,
  label,
  currentGenre,
}) {
  return (
    <button
      className={`Menubar_filter_button 
            ${currentGenre === genre ? "active" : ""}`}
      onClick={() => {
        handlerGenre(genre);
        if (genre === "all") {
          filterMethod("");
        } else {
          filterMethod(genre);
        }
      }}
    >
      {label}
    </button>
  );
}
