import React from "react";
import MenuBar from "./MenuBar";
import styles from "./css/Base.module.css";
import "./css/Sustainability.css";
import sustainability_bg from "./img/sustainability/sustainability_bg.png";
import sustainability_main_bg from "./img/sustainability/sustainability_main_bg.png";

// sustainability_main_bg.png

import SustainabilityCol from "./SustainabilityCol";

// rewrite this
import SustainabilityItemIcon1 from "./img/sustainability/icon/sustainability_icon_1.png";
import SustainabilityItemIcon2 from "./img/sustainability/icon/sustainability_icon_2.png";
import SustainabilityItemIcon3 from "./img/sustainability/icon/sustainability_icon_3.png";
import SustainabilityItemIcon4 from "./img/sustainability/icon/sustainability_icon_4.png";

import SustainabilityItem1_1 from "./img/sustainability/sustainability_item1-1.png";
import SustainabilityItem1_2 from "./img/sustainability/sustainability_item1-2.png";
import SustainabilityItem1_3 from "./img/sustainability/sustainability_item1-3.png";

import SustainabilityItem2_1 from "./img/sustainability/sustainability_item2-1.png";
import SustainabilityItem2_2 from "./img/sustainability/sustainability_item2-2.png";
import SustainabilityItem2_3 from "./img/sustainability/sustainability_item2-3.png";

import SustainabilityItem3_1 from "./img/sustainability/sustainability_item3-1.png";
import SustainabilityItem3_2 from "./img/sustainability/sustainability_item3-2.png";
import SustainabilityItem3_3 from "./img/sustainability/sustainability_item3-3.png";

import SustainabilityItem4_1 from "./img/sustainability/sustainability_item4-1.png";
import SustainabilityItem4_2 from "./img/sustainability/sustainability_item4-2.png";

export default function Sustainability() {
  const backgroundImageStyle = {
    backgroundImage: `url(${sustainability_bg})`,
    backgroundRepeat: `no-repeat`,
  };

  const backgroundMainImageStyle = {
    backgroundImage: `url(${sustainability_main_bg})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center 100%`,
  };

  return (
    <div>
      <MenuBar origin="sustainability" />
      <div
        className={`sustainability_sec ${styles.position_relative}`}
        style={backgroundImageStyle}
      >
        <div className={`sustainability_wrap ${styles.mx_auto}`}>
          <h1 className={`${styles.font_huninn}`}>Sustainability</h1>
          <p className={`${styles.font_huninn} text_wh`}>
            制定永續發展目標Sustainable Development<br></br>
            激發創意的力量，為人群、環境、客戶、產業夥伴們建立更美好未來。
          </p>
        </div>
      </div>
      <div className={`sustainability_sec ${styles.position_relative}`}>
        <div className={`sustainability_wrap ${styles.mx_auto}`}>
          <h1 className={`${styles.font_huninn} text_wh`}>
            里安制定永續目標 實踐綠色精神
          </h1>
          <p className={`${styles.font_huninn} text_wh`}>
            Set sustainable development goals.<br></br>
            Inspire the power of creativity to build better future for people,
            environment, customers and communities.
          </p>
        </div>
      </div>
      <div
        className={`${styles.d_flex} ${styles.justify_content_left_md} ${styles.flex_wrap} sustainability_sec`}
        style={backgroundMainImageStyle}
      >
        <SustainabilityCol
          dataIndex={0}
          mainIcon={SustainabilityItemIcon1}
          subIcon={[
            SustainabilityItem1_1,
            SustainabilityItem1_2,
            SustainabilityItem1_3,
          ]}
        />
        <SustainabilityCol
          dataIndex={1}
          mainIcon={SustainabilityItemIcon2}
          subIcon={[
            SustainabilityItem2_1,
            SustainabilityItem2_2,
            SustainabilityItem2_3,
          ]}
        />
        <SustainabilityCol
          dataIndex={2}
          mainIcon={SustainabilityItemIcon3}
          subIcon={[
            SustainabilityItem3_1,
            SustainabilityItem3_2,
            SustainabilityItem3_3,
          ]}
        />
        <SustainabilityCol
          dataIndex={3}
          mainIcon={SustainabilityItemIcon4}
          subIcon={[SustainabilityItem4_1, SustainabilityItem4_2]}
        />
      </div>
    </div>
  );
}
